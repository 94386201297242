<template>
  <div class="flex flex-col h-screen p-2 gap-2 text-white bg-blue-900">
    <div class="hodo-tv-mode flex w-full gap-2">
      <div
        class="hodo-tv-mode__cr-info flex max-w-xl bg-blue-500 p-2 border-2 border-solid border-blue-700"
      >
        <div
          class="flex flex-shrink text-xl font-bold uppercase pr-2 border-r-2 border-t-0 border-b-0 border-l-0 border-solid border-blue-700"
        >
          <span class="my-auto">{{ clinicRoom }}</span>
        </div>
        <div class="pl-2 flex-grow">
          <!-- Khai dev  -->
          <template v-for="doctor in doctors">
            <div
              v-if="doctors.length"
              :key="doctor.id"
              class="text-xl font-bold uppercase"
            >{{ doctor.Doctor.User.name }}</div>
          </template>
          <div
            class="pt-1 mt-1 border-t-2 border-r-0 border-b-0 border-l-0 border-solid border-blue-700"
          >
            <div class="flex justify-between text-xl">
              <label class="mr-1">Ngày:</label>
              <span>{{ currentDate() }}</span>
            </div>
            <div class="flex justify-between text-xl">
              <label class="mr-1">Giờ:</label>
              <span>{{ `${hours}:${minutes}:${seconds}` }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Khai dev  -->
      <div
        v-if="beingResult.length > 0 || nextTurn.length > 0"
        class="hodo-tv-mode__cr-present flex-1 flex flex-col flex-wrap bg-blue-500 p-2 border-2 border-solid border-blue-700"
        :set="(result = getPatientInfo())"
      >
        <div class="text-2xl text-center w-full uppercase">{{ result.statusExam }}</div>
        <div class="flex-1 flex w-full">
          <div
            class="inline-flex justify-center text-4xl font-bold w-full my-auto whitespace-pre-line"
          >
            <span class="whitespace-nowrap mr-2">Số {{result.data.line_number}} -</span>
            <span>{{ result.data.person.name }}</span>
          </div>
        </div>
      </div>
      <div
        v-else
        class="hodo-tv-mode__cr-present flex-1 flex flex-col flex-wrap bg-blue-500 p-2 border-2 border-solid border-blue-700"
      >
        <div class="text-2xl text-center w-full uppercase"></div>
        <div class="flex-1 flex w-full">
          <div
            class="inline-flex justify-center text-4xl font-bold w-full my-auto whitespace-pre-line"
          >
            <span class="mx-2">Chưa có bệnh nhân</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="hodo-tv-mode flex gap-2 w-full h-full">
        <div class="hodo-tv-mode__cr-table flex w-1/2">
          <div
            ref="waitingRefs"
            class="w-full h-full bg-blue-500 p-2 border-2 border-solid border-blue-700"
          >
            <div class="text-xl uppercase font-bold text-center pb-2">
              <span>Chờ khám ({{ waitingTotal }})</span>
            </div>
            <div>
              <el-table
                class="bg-transparent"
                :height="waitingHeight"
                :data="waitingData"
                header-row-class-name="bg-transparent"
                header-cell-class-name="text-center bg-transparent text-black"
                row-class-name="bg-transparent"
                cell-class-name="bg-transparent"
              >
                <el-table-column prop="line_number" label="STT" width="55">
                  <template slot-scope="scope">
                    <div class="text-center">{{scope.row.line_number}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="HỌ VÀ TÊN" width="180">
                  <template slot-scope="scope">
                    <div class="text-center truncate">{{scope.row.person.name}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="birthday" label="NĂM SINH" width="100">
                  <template slot-scope="scope">
                    <div
                      class="text-center"
                    >{{scope.row.person.birthday && formatBirthday(scope.row.person.birthday)}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="method" label="HÌNH THỨC" width="149">
                  <template slot-scope="scope">
                    <div
                      class="text-center"
                    >{{ scope.row.method && formatMethod(scope.row.method) }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="hodo-tv-mode__cr-table flex flex-col w-1/2 gap-2">
          <div
            ref="waitingReadResultRefs"
            class="w-full h-1/2 bg-blue-500 p-2 border-2 border-solid border-blue-700"
          >
            <div class="text-xl uppercase font-bold text-center pb-2">
              <span>Chờ đọc kết quả ({{ readingTotal }})</span>
            </div>
            <div>
              <el-table
                class="bg-transparent"
                :height="waitingReadResultHeight"
                :data="waitingReadResult"
                header-row-class-name="bg-transparent"
                header-cell-class-name="text-center bg-transparent text-black"
                row-class-name="bg-transparent"
                cell-class-name="bg-transparent"
              >
                <el-table-column prop="line_number" label="STT" width="55">
                  <template slot-scope="scope">
                    <div class="text-center">{{scope.row.line_number}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="HỌ VÀ TÊN" width="180">
                  <template slot-scope="scope">
                    <div class="text-center truncate">{{scope.row.person.name}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="birthday" label="NĂM SINH" width="100">
                  <template slot-scope="scope">
                    <div
                      class="text-center"
                    >{{scope.row.person.birthday && formatBirthday(scope.row.person.birthday)}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="method" label="HÌNH THỨC" width="149">
                  <template slot-scope="scope">
                    <div
                      class="text-center"
                    >{{ scope.row.method && formatMethod(scope.row.method) }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            ref="missResultRefs"
            class="w-full h-1/2 bg-blue-500 p-2 border-2 border-solid border-blue-700"
          >
            <div class="text-xl uppercase font-bold text-center pb-2">
              <span>Nhỡ lượt ({{ listTotal }})</span>
            </div>
            <div>
              <el-table
                class="bg-transparent"
                :height="missResultHeight"
                :data="missResult"
                header-row-class-name="bg-transparent"
                header-cell-class-name="text-center bg-transparent text-black"
                row-class-name="bg-transparent"
                cell-class-name="bg-transparent"
              >
                <el-table-column prop="line_number" label="STT" width="55">
                  <template slot-scope="scope">
                    <div class="text-center">{{scope.row.line_number}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="HỌ VÀ TÊN" width="180">
                  <template slot-scope="scope">
                    <div class="text-center truncate">{{scope.row.person.name}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="birthday" label="NĂM SINH" width="100">
                  <template slot-scope="scope">
                    <div
                      class="text-center"
                    >{{scope.row.person.birthday && formatBirthday(scope.row.person.birthday)}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="method" label="HÌNH THỨC" width="149">
                  <template slot-scope="scope">
                    <div
                      class="text-center"
                    >{{ scope.row.method && formatMethod(scope.row.method) }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import moment from 'moment'
// import Echo from 'laravel-echo'
// window.io = require('socket.io-client')
import * as constants from '../../utils/constants'

export default {
  name: 'TVModeExaminationRegister',
  computed: {},
  data () {
    return {
      waitingData: [],
      waitingReadResult: [],
      missResult: [],
      beingResult: [],
      nextTurn: [],
      params: {
        cr_id: null,
        clinic_id: null,
        doctor_id: null,
        start_time: null,
        end_time: null,
        status: null,
        page_num: 1,
        page_size: null,
        order: 'asc',
        sort_by: 'start_time'
      },
      hours: 0,
      minutes: 0,
      seconds: 0,
      waitingTotal: null,
      readingTotal: null,
      listTotal: null,
      waitingHeight: 472,
      waitingReadResultHeight: 236,
      missResultHeight: 236,
      clinicRoom: null,
      roomName: null,
      doctors: []
    }
  },
  beforeMount () {
    this.getHeightBox('waitingRefs')
    this.getHeightBox('waitingReadResultRefs')
    this.getHeightBox('missResultRefs')
  },
  watch: {
    // $echo: {
    //   handler (data) {
    //     this.onConnectSocket()
    //   }
    // },
    $conectedEcho: {
      handler () {
        this.onConnectSocket()
      }
    },
    'waitingData.length': {
      handler () {
        this.getHeightBox('waitingRefs')
      }
    },
    'waitingReadResult.length': {
      handler () {
        this.getHeightBox('waitingReadResultRefs')
      }
    },
    'missResult.length': {
      handler () {
        this.getHeightBox('missResultRefs')
      }
    }
  },
  mounted () {
    this.params.cr_id = this.$route.query.cr_id
    // this.params.doctor_id = this.$route.query.doctor_id
    this.params.clinic_id = this.$globalClinic.id
    this.params.start_time = moment().format('YYYY-MM-DD') + ' 00:00:00' // todo: get on query
    this.params.end_time = moment().format('YYYY-MM-DD') + ' 23:59:59' // todo: get on query
    this.params.page_num = this.$route.query.page_num
    this.getListPatientWaiting()
    this.getListPatientWaitingReadResult()
    this.getListPatientMiss()
    this.getBeingExam()
    this.getNextTurn()
    this.getConsultingRoomByID(this.params.cr_id)
    this.setTime()

    // TODO: Calculator % font size for screen
    // Standard height, for which the body font size is correct
    // var preferredFontSize = 180 // %
    // var preferredSize = 1024 * 768

    // console.log(window.innerWidth)
    // var currentSize = window.innerWidth * window.innerHeight
    // var scalePercentage = Math.sqrt(currentSize) / Math.sqrt(preferredSize)
    // var newFontSize = preferredFontSize * scalePercentage
    // const el = document.querySelectorAll('.hodo-tv-mode')
    // console.log(el, newFontSize)
  },
  methods: {
    async prepare () {
      await Promise.all([
        this.getListPatientWaiting(),
        this.getBeingExam(),
        this.getNextTurn(),
        this.getListPatientWaitingReadResult(),
        this.getListPatientMiss()
      ])
    },
    getPatientInfo () {
      if (this.beingResult.length > 0) {
        return {
          status: constants.EXAMINATION_STATUS.CODE.BEING,
          data: this.beingResult[0],
          statusExam: 'Đang trong phòng'
        }
      } else {
        return {
          status: constants.EXAMINATION_STATUS.CODE.BEING,
          data: this.nextTurn[0],
          statusExam: 'Mời bệnh nhân'
        }
      }
    },
    getHeightBox (refsBox) {
      if (refsBox === 'waitingRefs') {
        this.waitingHeight = this.$refs[refsBox]?.offsetHeight
          ? this.$refs[refsBox]?.offsetHeight - 36 - 4
          : this.waitingHeight
      } else if (refsBox === 'waitingReadResultRefs') {
        this.waitingReadResultRefs = this.$refs[refsBox]?.offsetHeight
          ? this.$refs[refsBox]?.offsetHeight - 36 - 4
          : this.waitingReadResultRefs
      } else if (refsBox === 'missResultRefs') {
        this.missResultRefs = this.$refs[refsBox]?.offsetHeight
          ? this.$refs[refsBox]?.offsetHeight - 36 - 4
          : this.missResultRefs
      }
    },
    async getConsultingRoomByID (id) {
      if (!id) return
      try {
        await this.$rf
          .getRequest('DoctorRequest')
          .getConsultingRoomByIDV2(id)
          .then(res => {
            // console.log(res)
            this.clinicRoom = res.data.data.Name
            this.doctors = res.data.data.DoctorWorkTimeSetting.slice(0, 3)
          })
      } catch (e) {
        console.log(e)
      }
    },
    async getNextTurn () {
      const nextTurnParams = this.params
      nextTurnParams.status = constants.EXAMINATION_STATUS.CODE.NEXT_CALL
      nextTurnParams.page_size = 1
      nextTurnParams.order = 'desc'
      nextTurnParams.sort_by = 'updated_at'

      await this.$rf
        .getRequest('DoctorRequest')
        .getPatientVisitsV2(nextTurnParams)
        .then(res => {
          this.nextTurn = res.data.data
        })
    },
    async getBeingExam () {
      const beingParams = this.params
      beingParams.status = constants.EXAMINATION_STATUS.CODE.BEING
      beingParams.page_size = 1
      await this.$rf
        .getRequest('DoctorRequest')
        .getPatientVisitsV2(beingParams)
        .then(res => {
          this.beingResult = res.data.data
          // console.log('being', this.beingResult)
        })
    },
    async getListPatientWaiting () {
      const waitingParams = this.params
      waitingParams.status = constants.EXAMINATION_STATUS.CODE.WAITING
      waitingParams.page_size = 10
      await this.$rf
        .getRequest('DoctorRequest')
        .getPatientVisitsV2(waitingParams)
        .then(res => {
          // console.log('waiting', res)
          this.waitingData = res.data.data
          this.waitingTotal = res.data.page.total
        })
    },
    async getListPatientWaitingReadResult () {
      const waitingResult = this.params
      waitingResult.status =
        constants.EXAMINATION_STATUS.CODE.WAITING_READ_RESULT
      waitingResult.page_size = 7
      await this.$rf
        .getRequest('DoctorRequest')
        .getPatientVisitsV2(waitingResult)
        .then(res => {
          // console.log('waiting read result', res)
          this.waitingReadResult = res.data.data
          this.readingTotal = res.data.page.total
        })
    },
    async getListPatientMiss () {
      const missedParams = this.params
      missedParams.status = constants.EXAMINATION_STATUS.CODE.MISSING
      missedParams.page_size = 3
      await this.$rf
        .getRequest('DoctorRequest')
        .getPatientVisitsV2(missedParams)
        .then(res => {
          // console.log('miss', res)
          this.missResult = res.data.data
          this.listTotal = res.data.page.total
        })
    },
    async onConnectSocket () {
      // const self = this
      console.log(this.$echo, this.$conectedEcho, this.$user)
      // Have this in case you stop running your laravel echo server
      if (this.$conectedEcho && this.$user) {
        // const user = this.$user
        this.$echo
          .channel(
            `tv_mode.${this.params.cr_id}.${this.$globalClinic.id}.${this.$globalClinic.organization.id}`
          )
          .listen('ApptEvent', data => {
            if (data.action) {
              this.prepare()
            }
          })
      }
    },
    formatMethod (method) {
      if (method === 1) {
        return 'Khám trực tiếp'
      } else {
        return 'Khám trực tuyến'
      }
    },
    formatBirthday (birthday) {
      return birthday && moment(birthday).format('YYYY')
    },
    currentDate () {
      const current = new Date()
      const date =
        current.getDate() +
        '/' +
        (current.getMonth() + 1) +
        '/' +
        current.getFullYear() +
        ' '
      return date
    },
    formatDate () {
      const current = new Date()
      const date =
        current.getFullYear() +
        '-' +
        (current.getMonth() + 1) +
        '-' +
        current.getDate() +
        ' ' +
        '23:59:59'
      return date
    },
    setTime () {
      setInterval(() => {
        const date = new Date()
        this.hours = date.getHours()
        this.minutes = this.checkSingleDigit(date.getMinutes())
        this.seconds = this.checkSingleDigit(date.getSeconds())
      }, 1000)
    },
    checkSingleDigit (digit) {
      return ('0' + digit).slice(-2)
    }
  },
  beforeDestroy () {},
  created () {}
}
</script>

<style scoped lang="scss">
::v-deep {
  .hodo-tv-mode {
    * {
      font-size: inherit !important;
      line-height: 1.2 !important;
    }
    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      display: none;
      background-color: rgba(32, 65, 155, 1) !important;
    }
    .el-table__body,
    .el-table__footer,
    .el-table__header,
    .el-table__empty-block {
      width: 100% !important;
    }
    .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: 2px solid rgba(32, 65, 155, 1) !important;
      color: #fff !important;
    }
    .el-table__empty-text {
      color: #fff !important;
    }
    .el-table th.el-table__cell {
      background-color: transparent !important;
    }
    .el-table th.el-table__cell .cell {
      word-break: normal !important;
      white-space: nowrap !important;
    }
    .el-table td.el-table__cell,
    .el-table__empty-text {
      font-size: 1.125rem;
    }
  }

  @media screen and (min-width: 768px) {
    .hodo-tv-mode {
      &__cr-info {
        font-size: 1.5vw !important;
      }
      &__cr-present {
        font-size: 3vw !important;
      }
      &__cr-table {
        font-size: 1.5vw !important;
      }
    }
  }
}
</style>
