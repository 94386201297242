<template>
  <div class="h-100vh">
    <div v-if="loading" class="loading-meet-room">
      <span class="spinner-border spinner-border-sm mr-2" role="status"></span>
    </div>
    <div v-if="!loading && email">
      <OTPLoginCpn
        :prop_email="email"
        @logged="logged"
        @changeOtp="
          o => {
            otp = o
          }
        "
      />
    </div>
  </div>
</template>

<script>
import OTPLoginCpn from '../../components/Users/OTPLoginCpn.vue'
import appUtils from '../../utils/appUtils'
import { mixinUser } from '../../utils/mixinUser'

export default {
  mixins: [mixinUser],
  name: 'MagicLinkJoinWsp',
  components: { OTPLoginCpn },
  data () {
    return {
      meet_room: null,
      members: [],
      loading: false,
      is_member: false,
      verified: false,
      login_otp: false,
      otp: '',
      email: ''
    }
  },
  created () {
    this.loading = true
  },
  mounted () {
    this.preparing()
    this.onClearUser()
  },
  methods: {
    backToList () {
      this.$router.push({ name: 'LoginPage' }).catch(e => console.log(e))
    },
    async preparing () {
      let self = this
      if (this.$route.query.email && this.$route.query.ws_token && this.$route.query.clinic_id) {
        this.verifyJoinLink()
      } else {
        self.$toast.open({
          message: 'Link không tồn tại',
          type: 'warning'
          // all other options
        })
        self.backToList()
      }
    },
    logged () {
      this.verifyJoinLink()
    },
    async verifyJoinLink () {
      const self = this
      const params = {
        email: this.$route.query.email,
        token: this.$route.query.ws_token,
        clinic_id: this.$route.query.clinic_id
      }
      await this.$rf
        .getRequest('AuthRequest')
        .verifyInviteDoctorWsLink(params, null, false)
        .then(r => {
          if (r.data) {
            self.verified = true
            self.accept()
          } else {
            self.$toast.open({
              message: 'Link không tồn tại hoặc hết hạn',
              type: 'warning'
              // all other options
            })
            self.backToList()
          }
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: 'Link không tồn tại hoặc hết hạn',
            type: 'warning'
            // all other options
          })
          self.backToList()
        })
        .finally(() => {
          return true
        })
    },
    async accept () {
      let self = this
      let p = {
        email: this.$route.query.email,
        token: this.$route.query.ws_token,
        clinic_id: this.$route.query.clinic_id
      }
      await this.$rf
        .getRequest('AuthRequest')
        .acceptInviteDoctorWs(p, null, false)
        .then(r => {
          console.log(r)
          self.login(r.data?.access_token)
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: 'Lỗi trong quá trình xử lý! Vui lòng đăng nhập lại',
            type: 'warning'
            // all other options
          })
          self.backToList()
        })
    },
    async login (access_token) {
      let self = this
      if (access_token) {
        try {
          appUtils.setLocalToken(access_token)

          let user = await this.$rf.getRequest('AuthRequest').me()
          await self.$rf
            .getRequest('DoctorRequest')
            .getClinic()
            .then(resp => {
              // gán data trả về
              let firstClinic = resp.data[0]
              let currClinic = resp.data?.find(c => parseInt(c.id) === parseInt(this.$route.query.clinic_id))
              appUtils.setDoctorClinic(currClinic || firstClinic)
            })
          if (user) {
            appUtils.setLocalUser(user.data)
            setTimeout(() => {
              self.checkHasPw()
            }, 300)
          }
        } catch (error) {
          console.log(error)
          self.$toast.open({
            message: 'Lỗi trong quá trình xử lý! Vui lòng đăng nhập lại',
            type: 'warning'
            // all other options
          })
          self.backToList()
        }
      }
    },
    async checkHasPw () {
      await this.$rf
        .getRequest('AuthRequest')
        .checkHasPw()
        .then(r => {
          if (r.data) {
            window.location.href = '/#/ws-mgmt'
          } else {
            let redirect = { redirect: 'ws-mgmt' }
            let routeData = this.$router.resolve({
              name: 'Infomation',
              query: redirect
            })
            window.location.href = routeData.href
          }
        })
    }
  }
}
</script>

<style scoped>
.loading-meet-room {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  background-color: #252a30 !important;
  position: absolute;
  top: 0;
}
.login-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  padding: 16px;
}
.login-form {
  width: 100%;
  max-width: 500px;
}
</style>
