<template src="../../../public/HODOTermCondition/dieukhoan.html"></template>

<script>
export default {

  name: 'Term',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped src="../../../public/HODOTermCondition/dieukhoan_files/Site.css"></style>