<template>
  <div class="w-full">
    <div class="modal fade" id="ModalEditTreeFamily" tabindex="-1" role="dialog">
      <div
        v-loading="isLoading"
        class="modal-dialog modal-md modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{
              $t("Thông tin Phả hệ")
              }}
            </h5>
          </div>
          <div class="modal-body txt-grey-900">
            <div class="font-bold mb-2 fs-18">Thông tin người thân</div>
            <div class="flex flex-col gap-2">
              <div>
                <div>Họ và tên</div>
                <div class="cs-card">{{selectedPersonInfo.data&&selectedPersonInfo.data.name}}</div>
              </div>
              <div>
                <div>Mối quan hệ nhân thân</div>
                <div
                  class="cs-card"
                >{{selectedPersonInfo.relative_definition?selectedPersonInfo.relative_definition.name:'Tôi'}}</div>
              </div>
              <div>
                <div>Giới tính</div>
                <div
                  class="cs-card"
                >{{selectedPersonInfo.data&&selectedPersonInfo.data.gender==="M"?"Nam":"Nữ"}}</div>
              </div>
              <div>
                <div>Ngày sinh</div>
                <div
                  class="cs-card"
                >{{selectedPersonInfo.person&&selectedPersonInfo.person.birthday?appUtils.formatDate(selectedPersonInfo.person.birthday):''}}</div>
              </div>
            </div>
            <div
              class="mt-2"
              v-if="selectedPersonInfo.person&&selectedPersonInfo.person.person_diseases&&selectedPersonInfo.person.person_diseases.length"
            >
              <div>Hồ sơ sức khoẻ</div>
              <div @click="handleRedirectHSSK" class="flex gap-2 cursor-pointer cs-card">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_33645_3428"
                    style="mask-type:alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_33645_3428)">
                    <path
                      d="M5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30773C3.5 4.8026 3.675 4.37503 4.025 4.02503C4.375 3.67503 4.80257 3.50003 5.3077 3.50003H11.6153V5.00001H5.3077C5.23077 5.00001 5.16024 5.03206 5.09612 5.09616C5.03202 5.16027 4.99997 5.2308 4.99997 5.30773V18.6923C4.99997 18.7692 5.03202 18.8397 5.09612 18.9039C5.16024 18.968 5.23077 19 5.3077 19H18.6923C18.7692 19 18.8397 18.968 18.9038 18.9039C18.9679 18.8397 19 18.7692 19 18.6923V12.3847H20.5V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM9.7192 15.3346L8.66538 14.2808L17.9461 5.00001H14V3.50003H20.5V10H19V6.05383L9.7192 15.3346Z"
                      fill="#1C1B1F"
                    />
                  </g>
                </svg>
                <div>{{selectedPersonInfo.data&&selectedPersonInfo.data.name}} - {{selectedPersonInfo.person.person_diseases[0].diseases_name}}</div>
              </div>
            </div>

            <div class="mt-6 text-sm font-medium flex justify-end">
              <!-- <button
                :disabled="isLoading"
                @click="handleSubmit"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
              >
                <div>{{ $t("Cập nhật") }}</div>
              </button>-->
              <!-- <button
                :disabled="isLoading"
                @click="handleClose"
                class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
              >
                <div>{{ $t("Hủy") }}</div>
              </button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'

export default {
  name: 'ModalEditTreeFamily',
  props: {
    selectedPersonInfo: Object
  },
  data () {
    return {
      isLoading: false,
      appUtils
    }
  },
  watch: {
    selectedPersonInfo: {
      deep: true,
      handler (dataPerson) {
        console.log(dataPerson)
      }
    }
  },
  created () {
    console.log(this.selectedPersonInfo)
  },
  methods: {
    handleSubmit () {},
    handleOpen () {
      window.$('#ModalEditTreeFamily').modal('show')
    },
    handleClose () {
      console.log('close')
      window.$('#ModalEditTreeFamily').modal('hide')
    },
    handleRedirectHSSK () {
      this.$router.push({
        name: 'TestInformationManagement',
        params: {
          id: this.selectedPersonInfo?.person?.person_diseases?.[0]?.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-card {
  padding: 8px;
  background-color: #eaeaea;
  border-radius: 4px;
}
</style>