var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-patients"},[_c('p',{staticClass:"title text-center text-4xl font-extrabold",style:(`color: ${_vm.titleColor}`)},[_vm._v(_vm._s(_vm.title))]),_c('el-table',{staticClass:"px-2",attrs:{"data":_vm.dataTable,"header-row-class-name":"d-none","row-class-name":"row-table ","stripe":""}},[_c('template',{slot:"empty"},[_c('span',{staticClass:"text-4xl"},[_vm._v("Không còn bệnh nhân nào")])]),_c('el-table-column',{attrs:{"label":"Lượt","width":_vm.screen < 768 
          ? '100' 
          :_vm.screen < 1200
          ? '110'
          : _vm.screen < 1440
          ? '180'
          : _vm.screen < 1536
          ? '200'
          : '220',"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"line-number"},[_vm._v(_vm._s(scope.row.line_number))])]}}])}),_c('el-table-column',{attrs:{"label":"Họ và tên","width":"","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"text-center truncate w-fit"},[_vm._v(_vm._s(_vm.convertName(scope.row.person.name))+" "+_vm._s(scope.row.person.birthday && `(${_vm.formatBirthday(scope.row.person.birthday)})`))])]}}])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }