<template>
  <div class="list-patients">
    <p class="title text-center text-4xl font-extrabold" :style="`color: ${titleColor}`">{{ title }}</p>
    <el-table
      :data="dataTable"
      header-row-class-name="d-none"
      row-class-name="row-table "
      stripe
      class="px-2"
    >
      <template slot="empty">
        <span class="text-4xl">Không còn bệnh nhân nào</span>
      </template>
      <el-table-column
        label="Lượt"
        :width="
            screen < 768 
            ? '100' 
            :screen < 1200
            ? '110'
            : screen < 1440
            ? '180'
            : screen < 1536
            ? '200'
            : '220'
        "
        align="center"
      >
        <template slot-scope="scope">
          <div class="line-number">{{ scope.row.line_number }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Họ và tên" width align="left">
        <template slot-scope="scope">
          <span
            class="text-center truncate w-fit"
          >{{ convertName(scope.row.person.name) }} {{scope.row.person.birthday && `(${formatBirthday(scope.row.person.birthday)})`}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'ListPatients',
  component: {},
  props: {
    title: { require: false, type: String, default: 'Chờ khám' },
    total: { require: false, type: Number, default: 0 },
    dataTable: { require: false, type: Array, default: [] },
    // tableWidth: { require: false, type: String, default: '100%' },
    titleColor: { require: false, type: String, default: '#20409b' }
  },
  data () {
    return {
      screen: window.innerWidth
    }
  },
  watch: {
    screen (value) {
      this.screen = value
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resizeWidth', this.onResize)
    // window.removeEventListener('resizeHeight', this.onResizeHeight)
  },
  methods: {
    formatBirthday (birthday) {
      return birthday && moment(birthday).format('YYYY')
    },
    onResize () {
      this.screen = window.innerWidth
    },
    convertName (fullName) {
      const nameArray = fullName.toUpperCase().split(' ')
      if (
        (nameArray.length === 2 && nameArray[1] === '') ||
        nameArray.length === 1
      ) {
        return fullName
      }
      const firstNameInitial = nameArray[0].charAt(0)
      const middleNameInitials = nameArray
        .slice(1, -1)
        .map(name => name.charAt(0))
        .join('.')
      const lastNameInitial = nameArray[nameArray.length - 1]
      const abbreviatedName = `${firstNameInitial}.${middleNameInitials} ${lastNameInitial}`
      return abbreviatedName
    }
  }
}
</script>
<style scoped lang="scss">
.list-patients {
  background: #fff;
  // border-radius: 8px;
  .title {
    padding-top: 16px;
    @media screen and (min-width: 1536px) {
      font-size: 56px;
      line-height: 64px;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 0;
    }
    @media screen and (max-width: 1536px) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 0;
      padding-top: 8px;
    }
  }
}
::v-deep.el-table {
  .el-table__header-wrapper .el-table__header .header-table .cell {
    font-size: 48px;
    line-height: 72px;
    color: #20419b;
    text-transform: uppercase;
    @media screen and (min-width: 1536px) {
      font-size: 64px;
      line-height: 96px;
    }
    @media screen and (max-width: 1536px) and (min-width: 1000px) {
      font-size: 40px;
      line-height: 56px;
    }
  }
  .el-table__body-wrapper {
    .el-table__body .el-table__row .el-table__cell .cell {
      font-size: 48px;
      line-height: 72px;
      font-weight: 400;
      color: #0c0c0d;
      width: 100%;
      @media screen and (min-width: 1536px) {
        font-size: 64px;
        line-height: 96px;
      }
      @media screen and (max-width: 1536px) and (min-width: 1000px) {
        font-size: 40px;
        line-height: 56px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 34px;
        line-height: 48px;
      }
    }
    .el-table__empty-block {
      height: 100%;
      padding: 60px 0;
      .el-table__empty-text {
        width: 100%;
      }
    }
  }
}
</style>
