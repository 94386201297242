<template>
  <div class="page txt-black-50">
    <div class="container login-page">
      <div class="row pt-lg-4 p-2">
        <div class="col-md-10 pt-lg-4 offset-md-1">
          <div class="card br-20">
            <div class="card-header header-card">
              <div class="mx-auto header-card-child" style>Xác thực thành công</div>
            </div>
            <div class="card-body">
              <div align="center">
                <p class="card-body-child">Chúc mừng bạn đã xác thực thành công</p>
                <img src="../../public/assets/images/icon/icon_success_confirmation.png" />
                <p>Đừng quên cập nhật thêm thông tin cá nhân để có trải nhiệm tốt hơn nhé</p>
              </div>
              <div class="form-footer text-center">
                <button
                  type="button"
                  class="btn bg-pri bd-pri text-white w-200"
                  @click="onContinue()"
                  :disabled="countdown > 0 && !logged"
                >{{ `Tiếp tục${countdown > 0 ? ` (${countdown}s)` : ''}` }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../utils/appUtils'
import { APP_MODE } from '../utils/constants'
export default {
  name: 'SuccessfulAuthenticationDoctor',
  data () {
    return {
      logged: false,
      interval: null,
      countdown: 10
    }
  },
  created () {},
  mounted () {
    if (this.countdown === 0) {
      clearInterval(this.interval)
      this.interval = null
    } else {
      this.interval = setInterval(() => {
        this.countdown--
      }, 1000)
    }

    if (this.$route.query.token) {
      this.loginWithToken(this.$route.query.token)
    } else {
      // this.$toast.open({
      //   message: 'Link không hợp lệ',
      //   type: 'warning'
      //   // all other options
      // })
      this.$router.replace({
        name: 'LoginPage'
      })
    }
  },
  methods: {
    onContinue () {
      this.$router.replace({
        name: 'CreateNewOrg'
      })
    },
    async loginWithToken (token) {
      const self = this
      if (token) {
        appUtils.setLocalToken(token)
        await self.$rf
          .getRequest('AuthRequest')
          .me()
          .then(resp => {
            if (resp) {
              appUtils.setLocalUser(resp.data)
              appUtils.setAppMode(APP_MODE.workspace)
              self.logged = true
            }
          })
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
<style scoped>
.txt-black-50 {
  background-color: #fafafa;
}
.w-200 {
  width: 200px;
}
.br-20 {
  border-radius: 20px;
}
.header-card {
  background-color: #20419b;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.header-card-child {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
.card-body-child {
  font-weight: bold;
  font-size: 20px;
}
</style>
