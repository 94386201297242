<template>
  <pdf src="../../../public/HODOServicesAgreementWS/HODOWorkspaceServiceAgreementWorkspace.pdf"></pdf>
</template>

<script>
    export default {
      name: 'ServicesAgreementWS'
    }
</script>

<style scoped>

</style>