<template>
    <div class="conntainer">
        <div class="text-center mt-5">
            <p class="robo-24-700 mb-3">HODO Statistics</p>
            <div class="box-all">
                <select class="form-select box-select">
                  <option :value="null" class="robo-14-600">Last 30 days</option>
                </select>
                    <p class="robo-14-400 ml-3"><span class="mr-1 robo-24-700">{{statistic.countNewWs}}</span>New Workspace</p>
                    <p class="robo-14-400 ml-48"><span class="mr-1 robo-24-700">{{statistic.countNewDoctor}}</span>New Doctor</p>
                    <p class="robo-14-400 ml-48"><span class="mr-1 robo-24-700">{{statistic.countTotalCase}}</span>Cases</p>
                    <p class="robo-14-400 ml-48"><span class="mr-1 robo-24-700">{{statistic.countMdtRoom}}</span>MDT rooms</p>
                    <p class="robo-14-400 ml-48"><span class="mr-1 robo-24-700">{{statistic.countMeetingRoom}}</span>Meeting rooms</p>
                  
            </div>
        </div>
    </div>
</template>
<style scoped>
.conntainer {
    background-color: #C7CDE9;
    background-image: linear-gradient(#C7CDE9, #FFFFFF);
    display: flex;
    justify-content: center;
    height: 1000px;
}
.box-all {
    background-color: #FFFFFF;
    height: 56px;
    width: 999px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
}
.robo-24-700 {
    font-size: 24px;
    font-weight: 700; 
}
.robo-14-400 {
    line-height: 56px;
    font-size: 14px;
    font-weight: 400;
}
.box-select {
    width: 130px;
    border-right: 1px solid lightgray;
    border-radius: 0;
    border-top-left-radius: 16px;
    border-end-start-radius: 16px;
    pointer-events: none;
}
.ml-48 {
    margin-left: 48px;
}
.robo-14-600 {
    font-size: 14px;
    font-weight: 600;
}
</style>
<script>
export default {
  name: 'StatisticPage',
  data () {
    return {
      statistic: []
    }
  },
  mounted () {
    let self = this
    self.getStatistic()
  },
  methods: {
    async getStatistic () {
      let self = this
      try {
        // self.page = parseInt(self.$route.query.page) || 1
        let params = {
        //   limit: 50,
        //   search: self.search,
        //   sort: self.sort || 'created_at',
        //   sort_by: self.sort_by || 'desc',
        //   page: self.page
        }
        await self.$rf.getRequest('CommonRequest').getStatistic(params).then(res => {
          self.statistic = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>