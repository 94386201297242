<template>
  <div class="container px-3 py-4">
    <el-tabs v-model="tabActive">
      <el-tab-pane :name="tab.value" v-for="tab in listTabs" :key="tab.value">
        <span slot="label" class="fs-24 flex items-center h-100"
          >{{ tab.label }} ({{
            tab.value === "1" ? totalNotSign : totalSigned
          }})</span
        >
        <el-card>
          <FilterSignature
            :type="typeDoc"
            :filters="filters"
            @filter="handleFilters"
          ></FilterSignature>
          <TableSignature
            :data="tabActive === '1' ? listDocs : listDocsSigned"
            :type="typeDoc"
            v-loading="isLoading"
            @open="handleOpenFile"
            @onRefresh="getListEnvelopes"
          ></TableSignature>
          <AppPagination
            :paginationProps="pagination"
            @onChangePagination="handleChangePagination"
          />
          <!-- <el-pagination
            background
            layout="sizes, prev, pager, next"
            class="flex justify-end mt-4"
            :total="tabActive === '1' ? totalNotSign : totalSigned"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="+pagination.page_num"
            :page-size="+pagination.page_size"
          ></el-pagination> -->
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import FilterSignature from '../../components/Signature/menu/FilterSignature.vue'
import TableSignature from '../../components/Signature/menu/TableSignature.vue'
import SignatureRequest from '../../api/request/SignatureRequest'
import { mixinQueryParams } from '../../utils/mixinQueryParams'
import AppPagination from '../../components/Common/AppPagination.vue'

export default {
  name: 'DigitalSignature',
  components: {
    FilterSignature,
    TableSignature,
    AppPagination
  },
  mixins: [mixinQueryParams],
  data () {
    return {
      tabActive: '1',
      typeDoc: 'not_sign' || 'signed',
      listTabs: [
        {
          label: 'Tài liệu chưa ký',
          value: '1'
        },
        {
          label: 'Tài liệu đã ký',
          value: '2'
        }
      ],
      listDocs: [],
      listDocsSigned: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      isLoading: false,
      totalSigned: 0,
      totalNotSign: 0,
      filters: {
        created_by: null,
        from_date: null,
        to_date: null,
        sign_from: null,
        sign_to: null,
        keyword: ''
      }
    }
  },
  watch: {
    tabActive () {
      if (this.tabActive === '1') this.typeDoc = 'not_sign'
      else this.typeDoc = 'signed'
      const newFilters = {
        tab: this.tabActive,
        ...this.filters
      }

      this.pagination.pageSize = 10
      this.handleFilters(newFilters)
    }
  },
  computed: {},
  created () {
    this.handleMapQuery()

    if (this.tabActive === '2') this.getListSignedEnvelopes()
    if (this.tabActive === '1') this.getListEnvelopes()
  },
  methods: {
    handleMapQuery () {
      const query = this.$route.query

      if (query?.tab) this.tabActive = query?.tab || '1'
      if (query.pageSize) {
        this.pagination.pageSize = +query.pageSize
      }
      if (query.currentPage) {
        this.pagination.currentPage = +query.currentPage
      }
      if (query.created_by) {
        this.filters.created_by = +query.created_by
      }

      this.filters = {
        created_by: Number(query.created_by) || null,
        keyword: query.keyword || '',
        from_date: query.from_date ? new Date(query.from_date * 1000) : null,
        to_date: query.to_date ? new Date(query.to_date * 1000) : null,
        sign_from: query.sign_from ? new Date(query.sign_from * 1000) : null,
        sign_to: query.sign_to ? new Date(query.sign_to * 1000) : null
      }
    },
    handleFilters (params) {
      const formatParams = this.handleFormatFilters(params)

      // this.filters = formatParams
      this.pagination.currentPage = 1
      this.handleUpdateFilter({ ...formatParams, currentPage: 1 })
      if (this.tabActive === '2') this.getListSignedEnvelopes(formatParams)
      if (this.tabActive === '1') this.getListEnvelopes(formatParams)
    },
    async getListSignedEnvelopes (filterData) {
      try {
        this.isLoading = true
        let params = {
          ...this.filters,
          ...filterData,
          page_num: this.pagination.currentPage || 1,
          page_size: this.pagination.pageSize || 10,
          order: 'desc',
          sort_by: 'created_at',
          status: 2,
          user_id: this.$user?.id
        }
        delete params.tab

        // if (filters?.created_by) params.created_by = filters.created_by
        // if (filters?.from_date) params.from_date = filters.from_date
        // if (filters?.to_date) params.to_date = filters.to_date
        // if (filters?.keyword) params.keyword = filters.keyword
        // if (filters?.sign_from) params.sign_from = filters.sign_from
        // if (filters?.sign_to) params.sign_to = filters.sign_to
        const request = new SignatureRequest()

        const res = await request.getListSignEnvelopes(params)
        this.listDocsSigned = res?.data?.data || []
        this.pagination = {
          currentPage: res?.data?.page?.page_num || this.pagination.currentPage,
          pageSize: res?.data?.page?.page_size || this.pagination.pageSize,
          totalItems: res?.data?.page?.total || 0
        }
        // this.pagination.totals = res?.data?.page?.total
        this.totalNotSign = res?.data?.total_not_sign
        this.totalSigned = res?.data?.total_signed
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getListEnvelopes (filterData) {
      try {
        this.isLoading = true
        const params = {
          ...this.filters,
          ...filterData,
          page_num: this.pagination.currentPage || 1,
          page_size: this.pagination.pageSize || 10,
          order: 'desc',
          sort_by: 'created_at',
          status: 1,
          user_id: this.$user?.id
        }

        delete params.sign_from
        delete params.sign_to
        delete params.tab

        // if (filters?.created_by) params.created_by = filters.created_by
        // if (filters?.from_date) params.from_date = filters.from_date
        // if (filters?.to_date) params.to_date = filters.to_date
        // if (filters?.keyword) params.keyword = filters.keyword
        // if (filters?.sign_from) params.sign_from = filters.sign_from
        // if (filters?.sign_to) params.sign_to = filters.sign_to

        const request = new SignatureRequest()

        const res = await request.getListSignEnvelopes(params)
        this.listDocs = res?.data?.data || []
        this.pagination = {
          currentPage: res?.data?.page?.page_num || this.pagination.currentPage,
          pageSize: res?.data?.page?.page_size || this.pagination.pageSize,
          totalItems: res?.data?.page?.total || 0
        }
        // this.pagination.totals = res?.data?.page?.total
        this.totalNotSign = res?.data?.total_not_sign
        this.totalSigned = res?.data?.total_signed
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleChangePagination (data) {
      if (data.currentPage) {
        this.pagination.currentPage = data.currentPage
      }

      if (data.pageSize) {
        this.pagination.pageSize = data.pageSize
      }

      const formatFilters = this.handleFormatFilters({
        ...this.pagination,
        ...this.filters
      })
      this.handleUpdateFilter(formatFilters)
      if (this.tabActive === '2') this.getListSignedEnvelopes(formatFilters)
      if (this.tabActive === '1') this.getListEnvelopes(formatFilters)
    },
    handleUpdateFilter (data) {
      delete data.totalItems

      this.handleSetFilter(data)
    },
    handleFormatFilters (params) {
      const formatParams = {
        ...params,
        from_date: params.from_date
          ? window.moment(params.from_date).startOf('days').unix()
          : null,
        // new Date().getTime() / 1000,
        to_date: params.to_date
          ? window.moment(params.to_date).endOf('days').unix()
          : null,
        sign_from: params.sign_from
          ? window.moment(params.sign_from).startOf('days').unix()
          : null,
        sign_to: params.sign_to
          ? window.moment(params.sign_to).endOf('days').unix()
          : null
      }

      return formatParams
    },
    handleOpenFile (type) {
      console.log('🚀 ~ handleOpenFile ~ type:', type)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.el-tabs {
  .el-tabs__item {
    height: 48px;
  }
}
.mt-4 {
  margin-top: 16px !important;
}
</style>
  