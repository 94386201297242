var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tv-mode"},[_c('audio',{ref:"audioRef",attrs:{"id":"callPatient","src":""}}),(
    _vm.waitingData.length == 0 &&
    _vm.waitingReadResult.length == 0 &&
    _vm.missResult.length == 0 &&
    _vm.beingResult.length == 0
  )?_c('div',{staticClass:"flex items-center h-screen banner-mode"},[_c('tv-mode-banner',{staticClass:"w-full",attrs:{"hours":_vm.hours,"minutes":_vm.minutes,"clinic-room":_vm.clinicRoom,"currentDate":_vm.currentDate(),"doctors":_vm.doctors,"checkShowDoctorQR":_vm.checkShowDoctorQR}})],1):_c('div',{staticClass:"patient-mode h-screen flex flex-col"},[_c('div',{staticClass:"flex justify-between header items-center"},[(_vm.checkShowDoctorQR)?_c('img',{staticClass:"qr_bs",attrs:{"src":"/images/QR_BS_Tu.jpg","alt":""}}):_vm._e(),(_vm.windowWidth > 1000)?_c('div',{staticClass:"text-4xl h-full flex-2"},[_c('div',{staticClass:"doctor-info"},_vm._l((_vm.doctors),function(doctor){return _c('span',{key:doctor.id,staticClass:"doctors"},[_vm._v(" "+_vm._s(doctor.Doctor.User.name)+" "),_c('span',{staticClass:"comma"},[_vm._v(",")])])}),0)]):_vm._e(),_c('div',{staticClass:"flex-3 items-end flex flex-col"},[_c('div',{staticClass:"clinic-name font-medium rounded-lg text-5xl"},[_c('span',[_vm._v(_vm._s(_vm.clinicRoom))])]),_c('div',{staticClass:"flex items-center justify-between gap-8 w-full"},[_c('div',{staticClass:"flex items-center gap-10 result",attrs:{"set":(_vm.result = _vm.getPatientInfo())}},[_c('div',[_c('span',{class:_vm.beingResult.length > 0 && _vm.nextTurn.length === 0
                ? 'examining'
                : (_vm.beingResult.length > 0 && _vm.nextTurn.length > 0) ||
                  (this.beingResult.length === 0 &&
                    this.nextTurn.length > 0)
                  ? 'preparing'
                  : 'text-[#0a0b0d]'},[_vm._v(_vm._s(_vm.result.statusExam))]),_c('span',{staticClass:"text-white ml-2"},[_vm._v(" "+_vm._s(_vm.result.data && _vm.result.data.line_number)+" - "+_vm._s(_vm.result.data && _vm.convertName(_vm.result.data.person.name))+" "),(_vm.result.data && _vm.result.data.person.birthday)?_c('span',[_vm._v("(")]):_vm._e(),_vm._v(" "+_vm._s(_vm.result.data && _vm.formatBirthday(_vm.result.data.person.birthday))+" "),(_vm.result.data && _vm.result.data.person.birthday)?_c('span',[_vm._v(")")]):_vm._e()])])]),_c('p',{staticClass:"text-3xl mb-0 text-white text-right current-time"},[_vm._v(" "+_vm._s(`${_vm.hours}:${_vm.minutes.toString()}:${_vm.seconds.toString()}`)+" ")])])])]),(
      _vm.waitingData.length == 0 &&
      _vm.waitingReadResult.length == 0 &&
      _vm.missResult.length == 0 &&
      _vm.beingResult.length != 0
    )?_c('div',{staticClass:"bg-white h-full flex items-center justify-center"},[_c('p',{staticClass:"text-lg font-medium"},[_vm._v("Không còn bệnh nhân nào")])]):_c('div',{staticClass:"px-3 grid gap-3 w-full flex-grow",class:(_vm.waitingReadResult.length === 0 &&
      _vm.missResult.length === 0 &&
      _vm.waitingData.length !== 0) ||
      ((_vm.waitingReadResult.length != 0 || _vm.missResult.length !== 0) &&
        _vm.waitingData.length === 0) || (_vm.waitingData.length !== 0 && _vm.waitingReadResult.length === 0 && _vm.windowWidth < 1000)
      ? '!grid-cols-1'
      : 'grid-cols-2'},[(_vm.waitingData.length != 0)?_c('div',{staticClass:"py-3"},[_c('list-patients',{staticClass:"h-full",attrs:{"dataTable":_vm.waitingData}})],1):_vm._e(),(_vm.waitingReadResult.length != 0 || _vm.missResult.length != 0)?_c('div',[_c('div',{staticClass:"flex flex-col h-full py-3 gap-3"},[(_vm.waitingReadResult.length != 0)?_c('list-patients',{staticClass:"flex-1 h-full",attrs:{"dataTable":_vm.waitingReadResult,"title":"Chờ đọc kết quả"}}):_vm._e(),(_vm.missResult.length != 0 && _vm.windowWidth > 1000)?_c('list-patients',{staticClass:"flex-1 h-full",attrs:{"dataTable":_vm.missResult,"title":"Nhỡ lượt"}}):_vm._e()],1)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }