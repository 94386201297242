<template>
  <div class="p-3">
    <div class="card-header">
      <h2 class="title">
        Quản Lý Mẫu
      </h2>
    </div>

    <div class="card-body">
      <div class="d-flex flex-wrap mb-3">
        <div class="text-muted">
          <router-link to="/admin/templates/add" class="btn btn-add">
            <span class="fa fa-plus mr-1"></span>
            Thêm Mẫu Mới
          </router-link>
        </div>
      </div>

      <div class="table-responsive">
        <table v-if="temps" class="table table-vcenter table-mobile-md card-table table-scroll">
          <thead>
          <tr>
            <th>Mẫu</th>
            <th>Tên Mẫu</th>
            <th>Loại</th>
            <th>Workspace</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="temp in temps.data" :key="temp.id">
            <td data-label="Mẫu">
              <span v-if="temp.thumb" class="avatar avatar-lg mr-2"
                    :style="'background-image: url(' + getImageURL(temp.thumb) + ')'"></span>
            </td>
            <td data-label="Tên Mẫu">
              <div>{{ temp.name }}</div>
              <small class="text-muted">{{ temp.component }}</small>
            </td>
            <td data-label="Loại">
              <div>{{ temp.type == 1 ? 'Mẫu Bệnh Án' : 'Mẫu Hoá Đơn' }}</div>
            </td>
            <td data-label="Workspace">
              <div>{{ temp.clinic ? temp.clinic.name : '-- Tất cả PK --' }}</div>
            </td>
            <td class="text-center">
              <a title="xóa" href="javascript:;" class="m-1 icon-action-table h2" @click="onRemove(temp.id)">
                <button class="btn btn-sm btn--bg-danger btn--person">
                  <div class="btn--delete"></div>
                </button>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <Pagination v-if="temps" :items="temps" :current_page="current_page" @onRefresh="getListing"></Pagination>
    </div>
  </div>
</template>
<script>
  import Pagination from '../../components/Pagination'
  import appUtils from '../../utils/appUtils'

  export default {
    name: 'AdminTemplateListing',
    components: {Pagination},
    data () {
      return {
        temps: {},
        current_page: 0
      }
    },
    mounted () {
      let self = this

      self.getListing()
    },
    methods: {
      async getListing () {
        let self = this

        let resp = await self.$rf.getRequest('AuthRequest').getTemplatesListing()
        self.temps = resp.data
      },
      async onRemove (id) {
        let self = this
        var r = confirm('Bạn có muốn xóa mẫu này không?')
        if (!r) {
          return false
        }

        await self.$rf.getRequest('AdminRequest').removeTemplate(id)
        await self.getListing()
      },
      getImageURL (avatar) {
        return appUtils.getImageURL(avatar)
      }
    }
  }
</script>
