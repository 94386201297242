<template>
  <div>
    <div class="text-center banner">
      <img src="/images/QR_BS_Tu.jpg" alt class="qr_bs" v-if="checkShowDoctorQR" />
      <div class="clinic-name font-semibold rounded-lg mx-auto text-7xl">{{ clinicRoom }}</div>
      <p class="text-5xl my-4 text-white">{{ `${hours}:${minutes.toString()}` }} {{ currentDate }}</p>
      <div class="text-5xl mt-2 mb-0 text-white font-semibold">
        <!-- <span>Bác sĩ:</span> -->
        <span v-for="doctor in doctors" :key="doctor.id" class="doctors">
          {{ doctor.Doctor.User.name }}
          <span class="comma">,</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TvModeBanner',
  component: {},
  props: {
    clinicRoom: {
      required: false,
      type: String,
      default: 'Phòng khám ...'
    },
    hours: {
      require: false,
      default: 0
    },
    minutes: {
      require: false,
      default: 0
    },
    // seconds: {
    //   require: false,
    //   type: String,
    //   default: ''
    // },
    currentDate: {
      require: false,
      type: String,
      default: ''
    },
    doctors: {
      require: false,
      type: Array,
      default: []
    },
    checkShowDoctorQR: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      //
    }
  }
}
</script>
<style scoped lang="scss">
.clinic-name {
  //   font-size: 56px;
  background: #fff;
  color: #20409b;
  width: fit-content;
  padding: 16px 24px;
}

.banner {
  background: #20409b;
  padding: 48px 0;
}

.doctors {
  &:last-child {
    .comma {
      display: none;
    }
  }
}

.qr_bs {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 16px;
}

@media screen and (max-width: 1000px) {
  .clinic-name {
    font-size: 56px;
    padding: 8px 16px;
  }

  .qr_bs {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .banner {
    padding: 32px 0 !important;
  }
}
</style>
