
<template>
  <div class="w-full h-full">
    <div ref="FamilyChart" id="FamilyChart" class="f3"></div>
    <ModalEditTreeFamily
      v-if="selectedPersonInfo"
      :selectedPersonInfo="selectedPersonInfo"
      ref="ModalEditTreeFamily"
    />
  </div>
</template>

<script>
import f3 from 'family-chart'
import * as d3 from 'd3'
import { Form } from './Elements/Form.js'
import ModalEditTreeFamily from './Components/ModalEditTreeFamily.vue'

export default {
  name: 'FamilyChart',
  components: { ModalEditTreeFamily },
  props: {
    treeData: Array
  },
  data () {
    return {
      selectedPersonInfo: null
      // treeData: [
      //   {
      //     id: '0',
      //     rels: {
      //       father: '16f2e241-6aea-406c-880c-2ac56a1c6546',
      //       mother: '63b2887c-04cf-4047-9df4-7c3f73c3a34b',
      //       spouses: ['8d9cfebb-b610-4dd9-8fe7-0781b3ac2ed6'],
      //       children: [
      //         '3c706b87-4512-4015-9318-3e8032bd7ac3',
      //         '3c706b87-4512-4015-9318-3e8032bd7ac4'
      //       ]
      //     },
      //     data: {
      //       'first name': 'Quang',
      //       'last name': 'Minh',
      //       birthday: '2000',
      //       avatar:
      //         'https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg',
      //       gender: 'M'
      //     }
      //   },
      //   {
      //     id: '16f2e241-6aea-406c-880c-2ac56a1c6546',
      //     data: {
      //       gender: 'M',
      //       'last name': 'Bố Quang',
      //       birthday: '1963',
      //       avatar: ''
      //     },
      //     rels: {
      //       children: ['0'],
      //       spouses: ['63b2887c-04cf-4047-9df4-7c3f73c3a34b']
      //     }
      //   },
      //   {
      //     id: '63b2887c-04cf-4047-9df4-7c3f73c3a34b',
      //     data: {
      //       gender: 'F',
      //       'last name': 'Mẹ Quang',
      //       birthday: '1972',
      //       avatar: ''
      //     },
      //     rels: {
      //       spouses: ['16f2e241-6aea-406c-880c-2ac56a1c6546'],
      //       children: ['0']
      //     }
      //   },
      //   {
      //     id: '3c706b87-4512-4015-9318-3e8032bd7ac3',
      //     data: {
      //       gender: 'M',
      //       'last name': 'Con Quang Trống',
      //       birthday: '2030',
      //       avatar: ''
      //     },
      //     rels: {
      //       mother: '8d9cfebb-b610-4dd9-8fe7-0781b3ac2ed6',
      //       father: '0'
      //     }
      //   },
      //   {
      //     id: '3c706b87-4512-4015-9318-3e8032bd7ac4',
      //     data: {
      //       gender: 'F',
      //       'last name': 'Con Quang Mái',
      //       birthday: '2030',
      //       avatar: ''
      //     },
      //     rels: {
      //       mother: '8d9cfebb-b610-4dd9-8fe7-0781b3ac2ed6',
      //       father: '0'
      //     }
      //   },
      //   {
      //     id: '8d9cfebb-b610-4dd9-8fe7-0781b3ac2ed6',
      //     data: {
      //       gender: 'F',
      //       'last name': 'Vợ Quang',
      //       birthday: '2000',
      //       avatar: ''
      //     },
      //     rels: {
      //       spouses: ['0'],
      //       children: ['3c706b87-4512-4015-9318-3e8032bd7ac3']
      //     }
      //   }
      // ]
    }
  },
  async created () {},
  mounted () {
    this.handleCreateTreeView()
  },
  methods: {
    // handleCreateTreeView () {
    //   const store = f3.createStore({
    //       data: this.treeData,
    //       node_separation: 250,
    //       level_separation: 150
    //     }),
    //     view = f3.d3AnimationView({
    //       store,
    //       cont: document.querySelector('#FamilyChart')
    //     }),
    //     Card = f3.elements.Card({
    //       store,
    //       svg: view.svg,
    //       card_dim: {
    //         w: 220,
    //         h: 70,
    //         text_x: 75,
    //         text_y: 15,
    //         img_w: 60,
    //         img_h: 60,
    //         img_x: 5,
    //         img_y: 5
    //       },
    //       card_display:this.cardDisplay(),
    //       mini_tree: true,
    //       link_break: false
    //     })

    //   view.setCard(Card)
    //   store.setOnUpdate(props => {
    //     view.update(props || {})

    //     d3.select(document.querySelector('#FamilyChart'))
    //       .selectAll('.card')
    //       .on('click', function () {
    //         const card_id = this.getAttribute('data-id')
    //         console.log(data().find(d => d.id === card_id))
    //       })
    //   })
    //   store.update.tree({ initial: true })
    // },
    async handleCreateTreeView () {
      const self = this
      const cont = document.querySelector('#FamilyChart')
      const card_dim = {
        w: 232,
        h: 70,
        text_x: 70,
        text_y: 15,
        img_w: 60,
        img_h: 60,
        img_x: 5,
        img_y: 5
      }
      const card_display = this.cardDisplay()
      const card_edit = this.cardEditParams()
      const store = f3.createStore({
        data: this.treeData,
        node_separation: 250,
        level_separation: 150
      })
      const view = f3.d3AnimationView({
        store,
        cont: document.querySelector('#FamilyChart'),
        card_edit
      })
      const Card = f3.elements.Card({
        store,
        svg: view.svg,
        card_dim,
        card_display,
        mini_tree: true,
        link_break: false,
        // cardEditForm,
        addRelative: f3.handlers.AddRelative({
          store,
          cont,
          card_dim,
          cardEditForm,
          labels: { mother: 'Add mother' }
        })
      })

      // const onUpdate = props => {
      //   view.update(props || {})
      // }

      view.setCard(Card)
      fetch('./elements/family-chart.css')
        .then(r => r.text())
        .then(
          text => (document.querySelector('#family-chart-css').innerText = text)
        )
      store.setOnUpdate(props => {
        // if (props.initial) {

        // }
        view.update(props || {})

        d3.select(document.querySelector('#FamilyChart'))
          .selectAll('.card')
          .on('click', function () {
            const card_id = this.getAttribute('data-id')

            const personInfo = self.treeData.find(
              d => d.id === Number(card_id)
            )

            self.selectedPersonInfo = personInfo

            setTimeout(() => {
              self.$refs.ModalEditTreeFamily.handleOpen()
            }, 500)
          })
      })
      store.update.tree({ initial: true })

      function cardEditForm (props) {
        const postSubmit = props.postSubmit
        props.postSubmit = ps_props => {
          postSubmit(ps_props)
        }
        // const modalRef = self.$refs.ModalEditTreeFamily
        const el = document.createElement('div')
        const edit = {
          el
          // open: () => modalRef.handleOpen(true),
          // close: () => {
          //   modalRef.handleClose()
          // }
        }

        Form({ ...props, card_edit, card_display, edit })
      }
    },

    cardEditParams () {
      return [
        { type: 'text', placeholder: 'first name', key: 'first name' },
        { type: 'text', placeholder: 'last name', key: 'last name' },
        { type: 'text', placeholder: 'birthday', key: 'birthday' },
        { type: 'text', placeholder: 'avatar', key: 'avatar' }
      ]
    },

    cardDisplay () {
      const d1 = d => `${d.data['name'] || ''}`

      const d2 = d => `${d.data['birthday'] || ''}`

      d1.create_form = '{name}'
      d2.create_form = '{birthday}'

      return [d1, d2]
    }
  }
}
</script>
<style lang="scss" scope >
.f3 {
  height: 100%;
  max-height: calc(100vh - 80px);
  width: 100%;
  max-width: 100%;
  margin: auto;
  position: relative;
  padding: 20px 20px 0;
}

.f3 .cursor-pointer {
  cursor: pointer;
}
.f3 svg.main_svg {
  width: 100%;
  height: 100%;
  background-color: white;
  color: #3b5560;
}
.f3 svg.main_svg text {
  fill: currentColor;
}
.f3 rect.card-female,
.f3 .card-female .card-body-rect,
.f3 .card-female .text-overflow-mask {
  fill: lightpink;
}
.f3 rect.card-male,
.f3 .card-male .card-body-rect,
.f3 .card-male .text-overflow-mask {
  fill: lightblue;
}
.f3 .card-genderless .card-body-rect,
.f3 .card-genderless .text-overflow-mask {
  fill: lightgray;
}
.f3 .card_add .card-body-rect {
  fill: #3b5560;
  stroke-width: 4px;
  stroke: #fff;
  cursor: pointer;
}
.f3 g.card_add text {
  fill: #fff;
}
.f3 .card-main {
  stroke: #000;
}
.f3 .card_family_tree rect {
  transition: 0.3s;
}
.f3 .card_family_tree:hover rect {
  transform: scale(1.1);
}
.f3 .card_add_relative {
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}
.f3 .card_add_relative circle {
  fill: rgba(0, 0, 0, 0);
}
.f3 .card_add_relative:hover {
  color: black;
}
.f3 .card_edit.pencil_icon {
  color: #fff;
  transition: 0.3s;
}
.f3 .card_edit.pencil_icon:hover {
  color: black;
}
.f3 .card_break_link,
.f3 .link_upper,
.f3 .link_lower,
.f3 .link_particles {
  transform-origin: 50% 50%;
  transition: 1s;
}
.f3 .card_break_link {
  color: #fff;
}
.f3 .card_break_link.closed .link_upper {
  transform: translate(-140.5px, 655.6px);
}
.f3 .card_break_link.closed .link_upper g {
  transform: rotate(-58deg);
}
.f3 .card_break_link.closed .link_particles {
  transform: scale(0);
}
.f3 .input-field input {
  height: 2.5rem !important;
}
.f3 .input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(-8px) scale(0.8);
  transform: translateY(-8px) scale(0.8);
}

path.link {
  stroke: #000;
}
</style>
    