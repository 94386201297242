<template>
    <div class="row pt-5">
        <div class="col-12 text-center">
            <button v-if="os_name" class="btn bg-pri bd-pri text-white" @click="goToCall()">Mở trong ứng dụng <span class="spinner-border spinner-border-sm ml-1" role="status" v-if="loading"></span></button>
        </div>
        <div class="col-12 text-center mt-3 mb-3">
            <p>Hiện chúng tôi chỉ hỗ trợ khám bệnh từ xa trên <span class="font-weight-bold">Android</span> và <span class="font-weight-bold">iOS</span></p>
            <p>Hệ điều hành hiện tại của bạn <span class="font-weight-bold">{{os_name ? os_name : 'không được hỗ trợ'}}</span></p>
        </div>
        <div v-if="os_name" class="col-12 text-center font-size-20">
          <p>Bạn chưa có ứng dụng?</p>
          <button class="btn bg-pri bd-pri text-white" @click="goToStore()">Tải ứng dụng cho {{os_name ? os_name : 'không được hỗ trợ'}}<span class="spinner-border spinner-border-sm ml-1" role="status" v-if="loading"></span></button>
      </div>
    </div>
</template>
<script>
export default {

  name: 'OpenCallPage',

  data () {
    return {
      loading: false,
      os_name: '',
      url_app: '',
      scheme_url: ''

    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    goToStore () {
      window.location = this.url_app
    },
    goToCall () {
      let self = this
      self.loading = true
      let now = new Date().valueOf()
      setTimeout(() => {
        if (new Date().valueOf() - now <= 1000) {
          alert('Ứng dụng HODO chưa được cài đặt!')
          self.loading = false
        }
      }, 800)
      window.location = self.scheme_url
    },
    getInfo () {
      let self = this
      let room_id = self.$route.params.id
      let token = self.$route.query.token
      if (navigator.appVersion.indexOf('like Mac') !== -1) {
        self.url_app = 'https://apps.apple.com/vn/app/hodo-905-vietnam/id1548237120?l=vi'
        self.os_name = 'iOS'
        self.scheme_url = 'hodopatient://meeting/' + room_id + '?token=' + token
      }
      if (navigator.appVersion.indexOf('Android') !== -1) {
        self.url_app = 'https://play.google.com/store/apps/details?id=com.hodo.patient'
        self.os_name = 'Android'
        self.scheme_url = 'hodopatient://hodo.patient/meeting/' + room_id + '?token=' + token
      }
    }
  }
}
</script>
<style lang="css" scoped>
</style>
