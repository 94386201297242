<template>
  <div>
    <ol class="breadcrumb mb-3" aria-label="breadcrumbs">
      <li class="breadcrumb-item">
        <router-link to="/admin/templates">Q.L Mẫu</router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Thêm Mẫu Mới
      </li>
    </ol>

    <div class="p-3">
      <!-- Page title -->
      <div class="page-header">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="font-size-25 font-weight-700">Thêm Mẫu Mới</h2>
        </div>
      </div>

      <form @submit.prevent="onSubmit" autocomplete="off">
        <div class="card-body mt-3">
          <div class="row">
            <div class="col-sm-6">
              <div class="mb-5">
                <label class="form-label">Ảnh Đại Diện</label>
                <div class="form-file">
                  <input type="file" class="form-control" accept="image/*" @change="onSelectedThumbFile($event)">
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="mb-5">
                <label class="form-label required mb-3">Tên mẫu</label>
                <input type="text" class="form-control mb-1" v-model="form.name" placeholder="Nhập tên mẫu...">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="mb-5">
                <label class="form-label required mb-3">Tên component</label>
                <input type="text" class="form-control mb-1" v-model="form.component"
                       placeholder="Nhập tên component...">
              </div>
            </div>

            <div class="col-sm-4">
              <div class="mb-5">
                <label class="form-label required mb-3">Loại template:</label>
                <select v-model="form.type" class="form-control">
                  <option value="">-- None --</option>
                  <option value="1">Mẫu Bệnh Án</option>
                  <option value="2">Mẫu Hoá Đơn</option>
                </select>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="mb-5">
                <label class="form-label mb-3">ID Workspace</label>
                <input type="number" class="form-control mb-1" v-model="form.clinic_id"
                       placeholder="Nhập ID Workspace...">
              </div>
            </div>
          </div>

          <div class="form-footer text-center">
            <button type="submit" class="btn btn-add" @click="onSubmit" :disabled="loading">
              <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
              <span>Thêm Mới</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AdminTemplateAdd',
    data () {
      return {
        loading: false,
        form: {
          name: '',
          component: '',
          thumb: '',
          type: null,
          clinic_id: null
        },
        thumb_file: ''
      }
    },
    methods: {
      async onSubmit () {
        let self = this
        let data = self.form
        data.thumb = self.thumb_file
        var formData = new FormData()

        for (var key in data) {
          if (key !== 'user') {
            formData.append(key, data[key])
          }
        }

        self.loading = true
        await self.$rf.getRequest('AdminRequest').addTemplate(formData).finally(() => {
          self.loading = false
        })

        return self.$router.push({path: '/admin/templates'})
      },
      onSelectedThumbFile (event) {
        this.thumb_file = event.target.files[0]
      }
    }
  }
</script>
